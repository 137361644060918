import { BiEnvelope, BiMap, BiMessageDetail, BiPhone } from "react-icons/bi";

type LinkProps = {
  label: string;
  url: string;
};

type ContactProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  link: LinkProps;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  contacts: ContactProps[];
};

export type Contact24Props = React.ComponentPropsWithoutRef<"section"> & Partial<Props>;

export const Contact24 = (props: Contact24Props) => {
  const { tagline, heading, description, contacts } = {
    ...Contact24Defaults,
    ...props,
  } as Props;
  return (
    <section id="relume" className="px-[5%] py-16 md:py-24 lg:py-28">
      <div className="container">
        <div className="rb-12 mb-12 max-w-lg md:mb-18 lg:mb-20">
          <p className="mb-3 font-semibold font-rem md:mb-4 text-customOrange">{tagline}</p>
          <h2 className="rb-5 mb-5 text-5xl font-rowdies text-orange-50 md:mb-6 md:text-7xl lg:text-8xl">
            {heading}
          </h2>
          <p className="md:text-md font-rem text-orange-50">{description}</p>
        </div>
        <div className="grid grid-cols-1 items-start justify-start gap-x-8 gap-y-12 md:grid-cols-2 md:gap-y-16 lg:grid-cols-4">
          {contacts.map((contact, index) => (
            <div key={index}>
              <div className="rb-5 mb-5 md:mb-6">{contact.icon}</div>
              <h3 className="mb-3 text-2xl font-bold font-rem text-orange-50 md:mb-4 md:text-3xl md:leading-[1.3] lg:text-4xl">
                {contact.title}
              </h3>
              <p className="mb-5 md:mb-6 font-rem text-orange-50">{contact.description}</p>
              <a className="underline font-rem text-orange-50" href={contact.link.url}>
                {contact.link.label}
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const Contact24Defaults: Contact24Props = {
  tagline: "Let's Chat",
  heading: "Contact us",
  description: "Reach out to us today we would love to hear from you",
  contacts: [
    {
      icon: <BiEnvelope className="size-12 text-customOrange" />,
      title: "Email",
      description:
        "Send us an Email",
      link: {
        label: "support@aiglobalnetworks.co.za",
        url: "#",
      },
    },
    {
      icon: <BiMessageDetail className="size-12 text-customOrange" />,
      title: "Live chat",
      description:
        "Chat with our Ai assistant and get an immediate response",
      link: {
        label: "Start new chat",
        url: "#",
      },
    },
    {
      icon: <BiPhone className="size-12 text-customOrange" />,
      title: "Phone",
      description:
        "Give us a call on",
      link: {
        label: "+27 67 805 4255",
        url: "#",
      },
    },
    {
      icon: <BiMap className="size-12 text-customOrange" />,
      title: "Office",
      description:
        "We are cuurently loctated at",
      link: {
        label: "123 Sample St, Sydney NSW 2000 AU",
        url: "#",
      },
    },
  ],
};
