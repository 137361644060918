"use client";

import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import clsx from "clsx";
// import { RxChevronRight } from "react-icons/rx";
import website from '../Assets/Website-Development.jpg'
import apps from "../Assets/Custom-mobile-app-development.webp";
import ai from '../Assets/AI blog.webp'

type ImageProps = {
  src: string;
  alt?: string;
};

type Feature = {
  image: ImageProps;
  heading: string;
  description: string;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  buttons: ButtonProps[];
  features: Feature[];
};

export type Layout458Props = React.ComponentPropsWithoutRef<"section"> & Partial<Props>;

export const Layout458 = (props: Layout458Props) => {
  const { tagline, heading, description, buttons, features } = {
    ...Layout458Defaults,
    ...props,
  } as Props;

  return (
    <section id="relume" className="overflow-hidden px-[5%] py-16 md:py-24 lg:py-28">
      <div className="container">
        <div className="rb-12 mb-12 grid auto-cols-fr grid-cols-1 items-start gap-x-5 gap-y-5 md:mb-18 md:grid-cols-2 md:gap-x-12 lg:mb-20 lg:gap-x-20 lg:gap-y-20">
          <div className="flex h-full flex-col">
            <p className="mb-3 font-semibold md:mb-4 font-rem text-customOrange">{tagline}</p>
            <h1 className="text-5xl font-bold md:text-7xl lg:text-8xl font-rowdies text-orange-200">{heading}</h1>
          </div>
          <div className="mx-[7.5%] flex flex-col justify-end md:mt-40">
            <p className="md:text-md font-rem text-orange-200">{description}</p>
            <div className="mt-6 flex flex-wrap items-center gap-4 md:mt-8">
              {buttons.map((button, index) => (
                <Button key={index} className="bg-customOrange hover:bg-orange-400 max-sm:w-full md:w-1/2 font-rubikOne" style={{height:'60px',borderRadius:'2rem', color:'rgb(254, 215, 170)'}} {...button}>
                {button.title}
              </Button>
              ))}
            </div>
          </div>
        </div>
        <div className="grid auto-cols-fr grid-cols-1 items-start gap-12 md:grid-cols-3 md:gap-8 lg:gap-12">
          {features.map((feature, index) => (
            <div
              key={index}
              className={clsx("w-full", {
                "md:mt-[25%]": index === 1,
                "md:mt-[50%]": index === 2,
              })}
            >
              <div className="rb-6 mb-6 w-full md:mb-8">
                <img
                  src={feature.image.src}
                  alt={feature.image.alt}
                  className="aspect-[3/2] w-full object-cover"
                />
              </div>
              <h2 className="mb-3 text-2xl font-bold font-rowdies text-orange-200 md:mb-4 md:text-3xl md:leading-[1.3] lg:text-4xl">
                {feature.heading}
              </h2>
              <p className="font-rem text-orange-200">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const Layout458Defaults: Layout458Props = {
  tagline: "Why Clients Choose Us,",
  heading: "Discover the Advantages of working with AI Global Networks",
  description:
    "We understand that the success of your business depends on reliable, always-available support and continuous optimization. That’s why we offer 24/7 support and maintenance services, ensuring that your software runs smoothly no matter the time of day. ",
  buttons: [
    { title: "Book My Consultation", variant: "secondary" },
    
  ],

  features: [
    {
      image: {
        src: website,
        alt: "Relume placeholder image 1",
      },
      heading: "Modern Web Design and Development",
      description:
        "At AI Global Networks, we specialize in creating modern, user-friendly websites that are visually stunning and fully functional across all devices. Our goal is to help you stand out in the digital landscape and attract your ideal customers.",
    },
    {
      image: {
        src: ai,
        alt: "Relume placeholder image 2",
      },
      heading: "Ai Powered Business Solutions",
      description:
        "we specialize in building powerful chatbots, conversational AI, and AI-driven automation systems that streamline your operations and help you achieve more with less effort. You will be able to reduce repetitive manual tasks allowing you to focus on higher-value work. ",
    },
    {
      image: {
        src: apps,
        alt: "Relume placeholder image 3",
      },
      heading: "Custom App/Software Development",
      description:
        "A custom app can enhance customer engagement, streamline operations, and increase revenue. With an app by AI Global Networks, you can reach your customers directly, sending personalized notifications and promotions that keep them coming back. Our team ensures each app is tailored to meet your unique business needs and goals.",
    },
  ],
};
