'use client'

import React, { useEffect, useRef } from 'react'
import { Navbar1 } from '../Components1/(1)Navbar'
import { Banner13 } from '../Components1/(2)Banner'
import { Header1 } from '../Components1/(3)Hero'
import { Layout27 } from '../Components1/(4)Stats'
import { Layout458 } from '../Components1/(5)Info'
import { Testimonial4 } from '../Components1/(6)Testimonial'
import { Layout351 } from '../Components1/(7)Works'
import { Faq5 } from '../Components1/(8)FAQs'
import { Contact24 } from '../Components1/(9)CTA'
import { Footer1 } from '../Components1/Footer'

export default function Home() {
  const bgRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (bgRef.current) {
        const { clientX, clientY } = e
        const moveX = clientX - window.innerWidth / 2
        const moveY = clientY - window.innerHeight / 2
        bgRef.current.style.transform = `translate(${moveX / 50}px, ${moveY / 50}px)`
      }
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return (
    <div className="min-h-screen bg-[#000000] relative overflow-hidden">
      <div ref={bgRef} className="absolute inset-0 transition-transform duration-300 ease-out">
        <div className="absolute inset-0 bg-gradient-to-br from-[#070401] via-[#3d2200] to-[#633a00] opacity-70"></div>
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjMTUwYjAwIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiMyNTE1MDAiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=')] opacity-20"></div>
        <div className="absolute inset-0 bg-gradient-radial from-transparent via-transparent to-[#1a0f00]/60"></div>
      </div>
      <div className="relative z-10">
        <Navbar1 />
        <Header1 />
        <Banner13 />
        <Layout27 />
        <Layout458 />
        <Testimonial4 />
        <Layout351 />
        <Faq5 />
        <Contact24 />
        <Footer1 />
      </div>
    </div>
  )
}