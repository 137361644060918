"use client";

import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { BiCheck } from "react-icons/bi";

type Billing = "monthly" | "yearly";

type Feature = {
  icon: React.ReactNode;
  text: string;
};

type PricingPlan = {
  planName: string;
  description: string;
  price: string;
  discount?: string;
  features: Feature[];
  button: ButtonProps;
};

type Tab = {
  value: Billing;
  tabName: string;
  plans: PricingPlan[];
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  defaultTabValue: Billing;
  tabs: Tab[];
};

export type Pricing25Props = React.ComponentPropsWithoutRef<"section"> & Partial<Props>;

export const Pricing25 = (props: Pricing25Props) => {
  const { tagline, heading, description, defaultTabValue, tabs } = {
    ...Pricing25Defaults,
    ...props,
  } as Props;
  const [activeTab, setActiveTab] = useState(defaultTabValue);
  const MotionTabsContent = motion.create(TabsContent);
  return (
    <section id="relume" className="px-[5%] py-16 md:py-24 lg:py-28 bg-black">
      <div className="container">
        <div className="mx-auto mb-8 max-w-lg text-center md:mb-10 lg:mb-12">
          <p className="mb-3 font-semibold font-rem text-white md:mb-4">{tagline}</p>
          <h1 className="mb-5 text-5xl font-medium text-white font-rowdies md:mb-6 md:text-7xl lg:text-8xl">{heading}<span className="text-customOrange">Development</span></h1>
          <p className="md:text-md font-rem text-white">{description}</p>
        </div>
        <Tabs defaultValue={defaultTabValue}>
          <TabsList className="mx-auto mb-12 w-fit">
            {tabs.map((tab, index) => (
              <TabsTrigger key={index} className="bg-customOrange border-customOrange"value={tab.value} onClick={() => setActiveTab(tab.value)}>
                {tab.tabName} 
              </TabsTrigger>
            ))}
          </TabsList>
          <AnimatePresence initial={false}>
            {tabs.map(
              (tab, index) =>
                tab.value === activeTab && (
                  <MotionTabsContent
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                    value={tab.value}
                    className="grid grid-cols-1 gap-8 lg:grid-cols-3"
                  >
                    {tab.plans.map((plan, index) => (
                      <PricingPlan key={index} plan={plan} billing={tab.value} />
                    ))}
                  </MotionTabsContent>
                ),
            )}
          </AnimatePresence>
        </Tabs>
      </div>
    </section>
  );
};

const PricingPlan = ({ plan, billing }: { plan: PricingPlan; billing: Billing }) => (
  <div className="flex h-full flex-col justify-start border border-customOrange px-6 py-8 md:p-8 hover:-translate-y-3 transition-transform duration-300">
    <h2 className="mb-1 text-md font-medium leading-[1.4] md:text-xl text-white font-rowdies">{plan.planName}</h2>
    <p className="text-white font-rem">{plan.description}</p>
    <div className="my-8 h-px w-full shrink-0 bg-white" />
    <h3 className="my-2 text-6xl font-bold md:text-9xl lg:text-10xl text-white font-rem">
      {plan.price}
      <span className="text-2xl font-bold md:text-3xl lg:text-4xl">
        {billing === "monthly" ? "/mo" : "/yr"}
      </span>
    </h3>
    {billing === "yearly" && "discount" in plan && <p className="font-medium text-red-600">{plan.discount}</p>}
    <div className="mt-6 md:mt-8">
    <Button {...plan.button}
    className="w-full font-rubikOne font-medium bg-customOrange border-none hover:bg-orange-400 hover:-translate-y-1 transition-transform duration-300">
    {plan.button.title}</Button>
    </div>
    <div className="my-8 h-px w-full shrink-0 bg-white" />
    <div className="grid grid-cols-1 gap-y-4 py-2">
      {plan.features.map((feature, index) => (
        <div key={index} className="flex self-start">
          <div className="mr-4 flex-none self-start text-white">{feature.icon}</div>
          <p className="text-white font-rem">{feature.text}</p>
        </div>
      ))}
    </div>
  </div>
);

export const Pricing25Defaults: Pricing25Props = {
  tagline: "Transformative",
  heading: "Web Design & ",
  description: "At AI Global Networks, we transform ideas into engaging digital experiences tailored to meet your business goals, ensuring your online presence captivates and converts.",
  defaultTabValue: "monthly",
  tabs: [
    {
      value: "monthly",
      tabName: "Monthly",
      plans: [
        {
          planName: "Startup Package",
          description: "Lorem ipsum dolor sit amet",
          price: "R540",
          features: [
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
          ],
          button: { title: "Get started" },
        },
        {
          planName: "Growth Package",
          description: "Lorem ipsum dolor sit amet",
          price: "R900",
          features: [
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
          ],
          button: { title: "Get started" },
        },
        {
          planName: "Premium Package",
          description: "Lorem ipsum dolor sit amet",
          price: "R2000",
          features: [
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
          ],
          button: { title: "Get started" },
        },
      ],
    },

    {
      value: "yearly",
      tabName: "Yearly",
      plans: [
        {
          planName: "Basic plan",
          description: "Lorem ipsum dolor sit amet",
          price: "R5185",
          discount: "Save 20%",
          features: [
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
          ],
          button: { title: "Get started" },
        },
        {
          planName: "Business plan",
          description: "Lorem ipsum dolor sit amet",
          price: "R9180",
          discount: "Save 15%",
          features: [
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
          ],
          button: { title: "Get started" },
        },
        {
          planName: "Enterprise plan",
          description: "Lorem ipsum dolor sit amet",
          price: "R20400",
          discount: "Save 15%",
          features: [
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
            { icon: <BiCheck className="size-6" />, text: "Feature text goes here" },
          ],
          button: { title: "Get started" },
        },
      ],
    },
  ],
};
