import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import hero from "../Assets/Frame 1.png"

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  heading: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

export type Header1Props = React.ComponentPropsWithoutRef<"section"> & Partial<Props>;

export const Header1 = (props: Header1Props) => {
  const { heading, description, buttons, image } = {
    ...Header1Defaults,
    ...props,
  } as Props;
  return (
    <section id="relume" className="px-[5%] py-16 md:py-24 lg:py-28 md:mt-12">
      <div className="container">
        <div className="grid grid-cols-1 gap-x-20 gap-y-12 md:gap-y-16 lg:grid-cols-2 lg:items-center">
          <div>
            <h1 className="mb-5 text-6xl font-medium md:mb-16 font-rowdies md:text-9xl lg:text-10xl text-orange-200">{heading}</h1>
            <p className="md:text-md md:mb-16 font-rem font-semibold text-orange-200">{description}</p>
            <div className="mt-6 flex gap-x-4 md:mt-8">
              {buttons.map((button, index) => (
                <Button key={index} className="bg-customOrange md:mt-10 max-sm:w-full md:w-1/2 font-rubikOne" style={{height:'60px',borderRadius:'2rem'}} {...button}>
                  {button.title}
                </Button>
              ))}
            </div>
          </div>
          <div>
            <img src={image.src} className="w-full object-cover" alt={image.alt} />
          </div>
        </div>
      </div>
    </section>
  );
};

export const Header1Defaults: Header1Props = {
  heading: "AI-Driven Innovation and Tailored Software Solutions",
  description:
    "At AI Global Networks, we specialize in delivering cutting-edge software development and AI-powered business solutions designed to meet your unique needs. Our mission is to empower you with tools that enhance your efficiency",
  buttons: [{ title: "Book my consultation" },],
  image: {
    src: hero,
    alt: "Relume placeholder image",
  },
};
