import efficient from '../Assets/efficient-removebg-preview.png'

type ImageProps = {
    src: string;
    alt?: string;
  };
  
  type StatsProps = {
    title: string;
    description: string;
  };
  
  type Props = {
    heading: string;
    description: string;
    stats: StatsProps[];
    image: ImageProps;
  };
  
  export type Layout27Props = React.ComponentPropsWithoutRef<"section"> & Partial<Props>;
  
  export const Layout27 = (props: Layout27Props) => {
    const { heading, description, image, stats } = {
      ...Layout27Defaults,
      ...props,
    } as Props;
    return (
      <section id="relume" className="px-[5%] py-16 md:py-24 lg:py-28">
        <div className="container">
          <div className="grid grid-cols-1 gap-y-12 md:grid-flow-row md:grid-cols-2 md:items-center md:gap-x-12 lg:gap-x-20">
            <div>
              <h2 className="rb-5 mb-5 text-4xl font-semibold leading-[1.2] md:mb-6 md:text-5xl lg:text-6xl font-rowdies text-orange-200">
                {heading}
              </h2>
              <p className="mb-6 md:mb-8 md:text-md font-rem text-orange-200">{description}</p>
              <div className="grid grid-cols-1 gap-6 py-2 sm:grid-cols-2">
                {stats.map((stat, index) => (
                  <div key={index}>
                    <h3 className="mb-2 text-5xl font-bold md:text-7xl lg:text-8xl text-customOrange">{stat.title}</h3>
                    <p className="font-rem text-orange-200">{stat.description}</p>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <img src={image.src} className="w-full object-cover h-96" alt={image.alt} />
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export const Layout27Defaults: Layout27Props = {
    heading: "Unlock the Future of Business Efficiency with AI Global Networks",
    description:
      " A Harvard study found that management consultants who incorporated AI tools into their work were more productive, completing tasks 25.1% more quickly",
    stats: [
      {
        title: "73%",
        description: "of companies waste time on manual tasks that AI can automate.",
      },
      {
        title: "80%",
        description: "improvement in productivity reported by staff using AI tools",
      },
    ],
    image: {
      src: efficient,
      alt: "Relume placeholder image",
    },
  };
  