import React from 'react'
import { Navbar1 } from '../Components1/(1)Navbar'
import { Pricing25 } from '../Components2/(1)WebService'
import { Pricing12 } from '../Components2/(2)ChatBot'
import { Pricing5 } from '../Components2/(3)Caller'
import { Footer1 } from '../Components1/Footer'

const Pricing: React.FC = () => {
    return (
    <div>
        <Navbar1 />
        <Pricing25 />
        <Pricing12 />
        <Pricing5 />
        <Footer1 />
    </div>
  )
}

export default Pricing